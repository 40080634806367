<template lang="pug">
div.view_container(v-bind:class="{view_container_small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.view_title Reset your password
  div.input_container
    input(v-model="password" type="password" placeholder="Type in password")
  div.input_container
    input(v-model="confirmPassword" type="password" placeholder="Re-enter the same password" v-on:keyup="keyEnterToSubmit")
  br
  button.cta(v-on:click="resetPassword" style="touch-action: manipulation" v-bind:class="{small_screen:isSmallScreen}") Reset password
</template>

<script>
/* global gtag */

import { RESET_PASSWORD_MUTATION } from '../graphql/mutations'
import config from '../appConfig'
import Header from '../components/Header'

export default {
  name: 'ResetPassword',
  props: {
  },
  components: {
    Header,
  },
  data() {
    return {
      password: null,
      confirmPassword: null,
      isSmallScreen: config.isSmallScreen(),
      token: this.$route.params.token,
    }
  },
  apollo: {

  },
  methods: {
    resetPassword: function() {
      if (this.password != this.confirmPassword) {
        this.$toast.error("Passwords do not match!")
        return
      }

      this.$apollo.mutate({
        mutation: RESET_PASSWORD_MUTATION,
        variables: {
          input: {
            resetPasswordToken: this.token,
            password: this.password
          }
        }
      }).then((data) => {
        console.log(data)
        var user = data.data.resetPassword.user
        var token = data.data.resetPassword.token
        var errors = data.data.resetPassword.errors

        this.errorsToast(errors)

        if (token) {
          // localStorage.setItem(process.env.VUE_APP_AUTH_TOKEN_KEY, token)
          this.$store.commit('setToken',token)
        }
        if (user) {
          this.$store.commit('signIn',user)
          // this.$router.push({name:"CreateOrUpdateProject"})
        }
        if (token && user) {
          gtag('event','login')
          this.$router.push({name:"Home"})
        } else if (!errors) {
          this.$toast.error('Error reseting password. Try sending another reset password token email.')
        }

      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.resetPassword()
      }
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    }
  },
  mounted: function () {
    // window.vue = this
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.signin {
  margin: 0 20px 0 0;
  padding: 26px 44px;
  border-radius: 35px;
  border-radius: 35px;
}

.small_screen {
  margin: 20px 0px;
}
</style>
